import { Box, Divider, Grid, Container as UnstyledContainer, Typography as UnstyledTypography } from "@mui/material";
import styled from '@mui/styled-engine';
import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Loader } from "../../components/Loader/Loader";
import useTitle from "../../hooks/useTitle";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import MessageCard from "../Chats/Components/MessageCard";
import { selectedFamilySelector } from "../Families/familySlice";
import { Message } from "./Message";
import { fetchMessages, messagesIsLoadingSelector, messagesSelector } from "./messagesSlice";

const Container = styled(UnstyledContainer)(({ theme }: any) => ({
  padding: theme.spacing(0, 1, 2),
}));

const InfoBox = styled(Box)(({ theme }: any) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

const ContainerGrid = styled(Grid)(() => ({
  height: isMobile ? "calc(100% - 8px)" : "100%",
  paddingLeft: isMobile ? "8px" : "108px",
  paddingRight: "8px",
  paddingBottom: "8px",
  flexDirection: "column",
  flexWrap: "nowrap",
}));

const TitleGrid = styled(Grid)(() => ({
  maxHeight: "50px",
}));

const Typography = styled(UnstyledTypography)(() => ({
  textTransform: "uppercase",
}));

const MessagesGrid = styled(Grid)(() => ({
  paddingBottom: "60px",
}));

export const Messages = () => {
  const { t } = useTranslation();
  useTitle(t("BabyWatch") + " - " + t("messages"));
  const dispatch = useAppDispatch();
  const selectedFamily = useAppSelector(selectedFamilySelector);
  const messages = useAppSelector(messagesSelector);
  const isLoading = useAppSelector(messagesIsLoadingSelector);

  useEffect(() => {
    if (selectedFamily?.community_id) {
      dispatch(fetchMessages(selectedFamily.community_id));
    }
  }, [selectedFamily, dispatch]);

  return (
    <ContainerGrid container>
      <TitleGrid item xs={12}>
        <Typography variant="h3" color="primary" gutterBottom>
          {t("messages")}
        </Typography>
      </TitleGrid>
      <InfoBox fontWeight="fontWeightLight" fontSize="fontSize" fontStyle="italic">
        {t("messageinfo")}
      </InfoBox>
      <Divider />
      <MessagesGrid item xs={12}>
        <Loader isLoading={isLoading} />
        {(messages === null || messages.length === 0) && (
          <InfoBox fontWeight="fontWeightLight" fontSize="fontSize" fontStyle="italic">
            {t("nomessages")}
          </InfoBox>
        )}
        <Box mt={2}>
          {messages?.map((message: Message, index) => (
            <Box mb={2} key={message.id}>
              <MessageCard message={{
                id: message.id, message: message.message, attachment_id: null, favorites: [], created_at: message.send_at,
                user: {
                  admin: 0, email: "", family_id: 0, first_name: message.name, id: 0, identity_id: "",
                  language: "", last_name: "", phonenumber: "", password: "", profile_image: "", remember_token: null,
                  role_name: "", username: "", avatar: { content: "" }, communities: [],
                }
              }} key={message.id} showFavorites={false} />
            </Box>
          ))}
        </Box>
      </MessagesGrid>
    </ContainerGrid>
  );
};
