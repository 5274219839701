import UnstyledFavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { AvatarGroup, Divider, IconButton as UnstyledIconButton } from "@mui/material";
import UnstyledAvatar from "@mui/material/Avatar";
import UnstyledCard from "@mui/material/Card";
import UnstyledCardActions from "@mui/material/CardActions";
import UnstyledCardContent from "@mui/material/CardContent";
import UnstyledCardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import { purple } from "@mui/material/colors";
import styled from '@mui/styled-engine';
import moment from "moment";
import React, { FunctionComponent } from "react";
import UnstyledImg from 'react-cool-img';
import config from "../../../../config";
import errorImage from '../../../assets/imagenotfound.png';
import loadingImage from '../../../assets/loading.gif';
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { User } from "../../Auth/Auth";
import { authTokenSelector, authUserSelector } from "../../Auth/authSlice";
import { selectedFamilySelector } from "../../Families/familySlice";
import { membersSelector } from "../../Members/membersSlice";
import { Message } from "../Chat";
import { addFavoriteChatMessage, removeFavoriteChatMessage } from "../chatSlice";

const FavoriteIconLikedByMe = styled(UnstyledFavoriteIcon)(() => ({
  fill: "#F22B4D"
}));

const FavoriteIconLikedByOthers = styled(FavoriteBorderIcon)(() => ({
  fill: "#F22B4D"
}));

const Avatar = styled(UnstyledAvatar)(() => ({
  backgroundColor: purple[900],
}));

const Card = styled(UnstyledCard)(({ theme }: any) => ({
  borderRadius: "0.5rem",
  overflow: "inherit",
  position: "relative",
  padding: theme.spacing(1),
}));

const CardContent = styled(UnstyledCardContent)(({ theme }: any) => ({
  padding: theme.spacing(1),
  overflow: "hidden",
}));

const Img = styled(UnstyledImg)(({ theme }: any) => ({
  maxWidth: "100%",
  borderRadius: theme.spacing(1),
}));

const CardActions = styled(UnstyledCardActions)(({ theme }: any) => ({
  padding: theme.spacing(1, 1, 0),
}));

const IconButton = styled(UnstyledIconButton)(({ theme }: any) => ({
  padding: theme.spacing(1),
}));

const CardHeader = styled(UnstyledCardHeader)(({ theme }: any) => ({
  padding: theme.spacing(1),
}));

const MessageCard: FunctionComponent<{
  message: Message;
  showFavorites: boolean;
}> = ({ message, showFavorites }) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(authUserSelector);
  const members = useAppSelector(membersSelector);
  const token = useAppSelector(authTokenSelector);
  const author = members.find(m => m.id === message.user?.id);
  const selectedFamily = useAppSelector(selectedFamilySelector);

  const setFavorite = () => {
    if (!selectedFamily?.community_id) return;

    if (message.favorites?.find((item) => item.id === user?.id) !== undefined) {
      dispatch(removeFavoriteChatMessage(selectedFamily.community_id, { ...message }));
    } else {
      dispatch(addFavoriteChatMessage(selectedFamily.community_id, { ...message }));
    }
  };

  const getFavoriteIcon = () => {
    const liked = message.favorites?.length > 0;
    const likedByMe = message.favorites?.find((item) => item.id === user?.id);

    if (liked) {
      if (likedByMe) {
        return <FavoriteIconLikedByMe />;
      } else {
        return <FavoriteIconLikedByOthers />;
      }
    } else {
      return <FavoriteBorderIcon />;
    }
  };

  const getFavoriteIconAndLikers = () => {
    return <>
      {getFavoriteIcon()}
      <AvatarGroup max={4}>
        {message.favorites?.map((user: User, index) => (
          <Avatar key={message.id} alt={user?.first_name} src={`data:image/jpeg;base64,${members.find(m => m.id === user?.id)?.avatar?.content}`} />
        ))}
      </AvatarGroup>
    </>;
  };

  let dateMoment = moment(message.created_at);
  if (!dateMoment.isValid()) {
    dateMoment = moment(message.created_at, "DD-MM-YYYY HH:mm:ss");
  }
  const dateString = dateMoment.isValid() ? dateMoment.format("D MMMM YYYY HH:mm") : message.created_at;

  const handleImageClick = (e: React.MouseEvent<HTMLImageElement>) => {
    if (e.detail === 2) setFavorite();
  };

  return (
    <Card elevation={1}>
      <CardHeader
        avatar={<Avatar alt={message.user?.first_name} src={author?.avatar?.content ? `data:image/jpeg;base64,${author.avatar.content}` : undefined} />}
        title={`${message.user?.first_name} ${message.user?.last_name ?? ""}`}
        subheader={dateString}
      />
      <CardContent>
        {(message.attachment_id && message.attachment_id > 0) ?
          <>
            <Img
              key={message.id}
              src={`${config.apiUrl}/file/${message.attachment_id}?key=${token}`}
              alt="placeholder"
              placeholder={loadingImage}
              error={errorImage}
              className="cardContentImg"
              onClick={handleImageClick} />
            <br />
          </>
          : ''}
        {message.message.split("\n").map((line) => (
          <Typography key={message.id} variant="body1">
            {line}
          </Typography>
        ))}
      </CardContent>
      {showFavorites && (
        <>
          <Divider />
          <CardActions disableSpacing>
            <IconButton
              color="primary"
              aria-label="send"
              edge="start"
              onClick={setFavorite}
              size="large">
              {getFavoriteIconAndLikers()}
            </IconButton>
          </CardActions>
        </>
      )}
    </Card>
  );
};

export default MessageCard;
