import { Box, CircularProgress } from "@mui/material";
import React, { ReactNode, useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useMount } from "react-use";
import { Callback } from "./components/Callback/Callback";
import Layout from "./components/Layout/Layout";
import { User } from "./domain/Auth/Auth";
import { Login } from "./domain/Auth/Login";
import { Unauthorized } from "./domain/Auth/Unauthorized";
import { authIsAdministratorSelector, authIsAuthenticatedSelector, authIsCustomerSelector, authIsLoadingSelector, authUserSelector, authVerify, signOut } from "./domain/Auth/authSlice";
import { Chats } from "./domain/Chats/Chats";
import { Family } from "./domain/Families/Family";
import { FamilyOverview } from "./domain/Families/FamilyOverview";
import { FamilySelect } from "./domain/Families/FamilySelect";
import { FamiliesTable } from "./domain/Families/Tables/FamiliesTable";
import { fetchFamilies, fetchFamiliesWithDetails, fetchFamily, selectedFamilySelector } from "./domain/Families/familySlice";
import { Loading } from "./domain/Loading/Loading";
import { MemberDeviceTable } from "./domain/Members/Tables/MemberDeviceTable";
import { Messages } from "./domain/Messages/Messages";
import { NotActive } from "./domain/NotActive/NotActive";
import { NotFound } from "./domain/NotFound/NotFound";
import { Patients } from "./domain/Patients/Patients";
import { UsersTable } from "./domain/Users/UsersTable";
import { VideoList } from "./domain/Video/VideoList";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import userManager from "./utils/userManager";

type AuthorizationLevel = "00_NotAuthenticated" | "01_Member" | "02_Parent" | "03_Customer" | "04_Administrator";

function homepageOrLoading(u: User | undefined, authorizationLevel: AuthorizationLevel): ReactNode {
  if (!u) return (<Loading />);

  if (authorizationLevel >= "03_Customer") {
    return (<Navigate to="/families" />);
  } else if (u.communities?.length == 1) {
    return (<Navigate to="/family" />);
  } else if (u.communities?.length > 1) {
    return (<Navigate to="/familyselect" />);
  } else {
    return <Unauthorized />
  }
}

const getAppRoutes = (user: User | undefined, selectedFamily: Family | undefined,
  authorizationLevel: AuthorizationLevel) => {
  return (
    <Routes>
      {selectedFamily?.deactivation_date == null ? (
        <Route element={<Layout />}>
          <Route element={authorizationLevel == "00_NotAuthenticated" && <Unauthorized />} />

          <Route path="login" element={<Login />} />

          <Route path="families" element={authorizationLevel >= "03_Customer" ? <FamiliesTable /> : <Navigate to="/login" />} />
          <Route path="familyselect" element={<FamilySelect />} />
          <Route path="devices" element={authorizationLevel >= "03_Customer" ? <MemberDeviceTable /> : <Navigate to="/login" />} />
          <Route path="users" element={authorizationLevel == "04_Administrator" ? <UsersTable /> : <Navigate to="/login" />} />

          <Route path="family" element={selectedFamily?.community_id ? <FamilyOverview /> : <Unauthorized />} />
          <Route path="babies" element={selectedFamily?.community_id ? <Patients /> : <Unauthorized />} />
          <Route path="chat" element={selectedFamily?.community_id ? <Chats /> : <Unauthorized />} />
          <Route path="video" element={selectedFamily?.community_id ? <VideoList /> : <Unauthorized />} />
          <Route path="messages" element={authorizationLevel >= "02_Parent" ? <Messages /> : <Unauthorized />} />

          <Route path="settings" element={authorizationLevel == "04_Administrator" ? <UsersTable /> : <Navigate to="/login" />} />
          <Route path="settings" element={authorizationLevel >= "03_Customer" ? <MemberDeviceTable /> : <Navigate to="/login" />} />
        </Route>
      ) : (
        <Route path="*" element={<Navigate to="/deactivated" />} />
      )}
      <Route path="/" element={authorizationLevel == "00_NotAuthenticated" ? (
        <Navigate to="/login" />
      ) : homepageOrLoading(user, authorizationLevel)
      }>
      </Route>
      <Route path="/callback" element={<Callback />} />
      <Route path="/deactivated" element={<NotActive />} />
      <Route path="/.env" element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export const AppRoutes = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector(authIsAuthenticatedSelector);
  const isCustomer = useAppSelector(authIsCustomerSelector);
  const isAdmin = useAppSelector(authIsAdministratorSelector);
  const isLoading = useAppSelector(authIsLoadingSelector);
  const user = useAppSelector(authUserSelector);
  const selectedFamily = useAppSelector(selectedFamilySelector);

  userManager.events.addAccessTokenExpired(() => {
    dispatch(signOut());
    navigate("/login");
  });

  useEffect(() => {
    if (!user) return;

    if (isCustomer) {
      dispatch(fetchFamiliesWithDetails);
    } else if (user.communities?.length === 1) {
      const communityId = user.communities[0].community_id;
      dispatch(fetchFamily(communityId));
    } else if (user.communities?.length > 1) {
      dispatch(fetchFamilies());
    }
  }, [user, dispatch, isCustomer]);

  useMount(() => {
    if (isAuthenticated) {
      try {
        dispatch(authVerify());
      } catch (e) {
        dispatch(signOut());
      }
    }
  });

  if (isAuthenticated && isLoading) {
    return (
      <Box display="flex" justifyContent="center" p={2}>
        <CircularProgress />
      </Box>
    );
  }

  let authorizationLevel: AuthorizationLevel;
  if (!isAuthenticated) {
    authorizationLevel = "00_NotAuthenticated";
  } else if (isAdmin) {
    authorizationLevel = "04_Administrator";
  } else if (isCustomer) {
    authorizationLevel = "03_Customer";
  } else if (user?.communities.some(c => c.community_id === selectedFamily?.community_id && c.is_parent) ?? false) {
    authorizationLevel = "02_Parent";
  } else {
    authorizationLevel = "01_Member";
  }

  return getAppRoutes(user, selectedFamily, authorizationLevel);
};
