import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Avatar, Divider, IconButton, List, ListItem, ListItemText, styled } from '@mui/material';
import { PopupState } from 'material-ui-popup-state/hooks';
import React, { FunctionComponent } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { authUserSelector } from '../../domain/Auth/authSlice';
import { setUser } from '../../domain/Settings/settingsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import userManager from '../../utils/userManager';

export const UserMenu: FunctionComponent<{
  popupState: PopupState;
}> = ({ popupState }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(authUserSelector);

  const signOut = async () => {
    await userManager.signoutRedirect();
  };

  const closeMenu = () => {
    popupState.setOpen(false);
  };

  const openSettings = () => {
    closeMenu();
    dispatch(setUser(user));
  };

  const WideList = styled(List)(({ theme }: any) => ({
    width: isMobile ? '320px' : '380px',
  }));

  return (
    <WideList aria-label="mailbox folders">
      <ListItem
        onClick={openSettings}
        secondaryAction={<Avatar src={user?.avatar?.content ? `data:image/jpeg;base64,${user.avatar.content}` : '.'} />}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        }}
      >
        <ListItemText id="text-profile" primary={`${user?.first_name} ${user?.last_name ?? ''}`} />
      </ListItem>
      <Divider />
      <ListItem
        onClick={signOut}
        secondaryAction={
          <IconButton edge="end" size="large" sx={{ right: '8px' }}>
            <ExitToAppIcon />
          </IconButton>
        }
        sx={{
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        }}
      >
        <ListItemText>{t('signout')}</ListItemText>
      </ListItem>
    </WideList>
  );
};
