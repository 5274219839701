import { Button, Typography, Container as UnstyledContainer } from "@mui/material";
import styled from '@mui/styled-engine';
import "moment/locale/nl";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "../../../styles";
import useTitle from "../../hooks/useTitle";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import userManager from "../../utils/userManager";
import { authUserSelector } from "../Auth/authSlice";
import { selectedFamilySelector } from "../Families/familySlice";
import logo from "./assets/logo.png";
import { fetchDownload, getDownloadAvailable, getParentInThisCommunity, setDownloadAvailable, setIsParentInThisCommunity } from "./notActiveSlice";

const Container = styled(UnstyledContainer)(({ theme }: any) => ({
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));

const NotFoundMessage = styled(Typography)(({ theme }: any) => ({
  fontWeight: 600,
  textAlign: "center",
}));

const Logo = styled("img")(({ theme }: any) => ({
  maxWidth: "100%",
  paddingBottom: theme.spacing(1),
}));

const Buttons = styled("div")(({ theme }: any) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));

const BackButton = styled(Button)(({ theme }: any) => ({
  ...styles.pinkBackground,
  padding: theme.spacing(1, 3),
  fontSize: "1.125rem",
  borderRadius: "2rem",
  lineHeight: "2rem",
  textDecoration: "none",
  marginTop: "0.5rem",
}));

const DownloadButton = styled(Button)(({ theme }: any) => ({
  ...styles.purpleBackground,
  padding: theme.spacing(1, 3),
  fontSize: "1.125rem",
  borderRadius: "2rem",
  lineHeight: "2rem",
  textDecoration: "none",
}));

const signOut = async () => {
  await userManager.signoutRedirect();
};

export const NotActive = () => {
  const { t } = useTranslation();
  useTitle(t("BabyWatch") + " - " + t("Community deactivated"));

  const dispatch = useAppDispatch();
  const user = useAppSelector(authUserSelector);
  const selectedFamily = useAppSelector(selectedFamilySelector);
  const downloadAvailable = useAppSelector(getDownloadAvailable);
  const isParentInThisCommunity = useAppSelector(getParentInThisCommunity);

  useEffect(() => {
    const downloadAvailable = selectedFamily?.state_name == "Accepted";
    const isParentInThisCommunity = user?.communities.some(c => c.community_id === selectedFamily?.community_id && c.is_parent);

    dispatch(setDownloadAvailable(downloadAvailable));
    dispatch(setIsParentInThisCommunity(isParentInThisCommunity));
  }, [dispatch, selectedFamily, user]);


  return (
    <Container>
      <Logo
        src={logo}
        alt="page not found logo"
      />
      <NotFoundMessage variant="h4" color="primary" gutterBottom> {isParentInThisCommunity && downloadAvailable ? (t("Download Message")) : (t("Not Active Message"))}</NotFoundMessage>
      <br />
      <Buttons>
        {downloadAvailable && isParentInThisCommunity && selectedFamily?.community_id && (
          <DownloadButton onClick={() => dispatch(fetchDownload(selectedFamily.community_id))} color="primary">
            {t("Download PDF")}
          </DownloadButton>
        )}
        <BackButton onClick={signOut} color="primary">
          {t("Back to login")}
        </BackButton>
      </Buttons>
    </Container>
  );
};
