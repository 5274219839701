import { Build, Chat, LiveTv, NotificationImportant, People, PermDeviceInformation, SupervisorAccount } from "@mui/icons-material";
import { Toolbar } from "@mui/material";
import styled from '@mui/styled-engine';
import React, { FunctionComponent } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { authIsAdministratorSelector, authIsAuthenticatedSelector, authIsCustomerSelector, authUserSelector } from "../../domain/Auth/authSlice";
import { selectedFamilySelector } from "../../domain/Families/familySlice";
import { useAppSelector } from "../../store/hooks";
import { AppHeader } from "../AppHeader/AppHeader";
import { NavigationMenu } from "../NavigationMenu";
import { TabNavigation } from "../TabNavigation/TabNavigation";
import Drawer from "./Drawer/Drawer";

// const FlexDiv = styled("div")`
//   display: "flex";

//   height: "calc(100vh - 65px)";
//   background-color: red;
// `
const FlexDiv = styled("div")(() => ({
  // display: "flex",
  height: "calc(100vh - 65px)",
}));

const MainContent = styled("main")(({ theme }: any) => ({
  // flexGrow: 1,
  minWidth: 0,
  paddingTop: theme.spacing(3),
  height: "100%",
}));

const Layout: FunctionComponent = () => {
  const { t } = useTranslation();
  const isAuthenticated = useAppSelector(authIsAuthenticatedSelector);
  const user = useAppSelector(authUserSelector);
  const isAdmin = useAppSelector(authIsAdministratorSelector);
  const isCustomer = useAppSelector(authIsCustomerSelector);
  const selectedFamily = useAppSelector(selectedFamilySelector);

  let menus: NavigationMenu[] = [];

  if (isAdmin || isCustomer) {
    menus.push({ title: t("families"), icon: <SupervisorAccount />, url: "/families", adminOrder: 0 });
    menus.push({ title: t("devices"), icon: <PermDeviceInformation />, url: "/devices", adminOrder: 1 });
    if (isAdmin) {
      menus.push({ title: t("administrators"), icon: <Build />, url: "/users", adminOrder: 6 });
    }
  }

  if (!isCustomer && !isAdmin && ((user?.communities?.length ?? 0) > 1)) {
    menus.push({ title: t("families"), icon: <SupervisorAccount />, url: "/familyselect", adminOrder: 1 });
  }

  if (selectedFamily?.community_id) {
    menus.push({ title: t("family"), icon: <People />, url: "/family", adminOrder: 2 });
    menus.push({ title: t("chat"), icon: <Chat />, url: "/chat", adminOrder: 4 });
    menus.push({ title: t("video"), icon: <LiveTv />, url: "/video", adminOrder: 3 });

    if (user?.communities.some(c => c.community_id === selectedFamily.community_id && c.is_parent) || isAdmin) {
      menus.push({ title: t("messages"), icon: <NotificationImportant />, url: "/messages", adminOrder: 5 });
    }
  }

  if (isAdmin || isCustomer) {
    menus = menus.sort((a, b) => a.adminOrder - b.adminOrder);
  }


  return (
    <FlexDiv>
      <AppHeader navigationItems={menus} />

      {isAuthenticated && <Drawer show={!isMobile} menus={menus} />}

      <MainContent>
        <Toolbar />
        <Outlet />
      </MainContent>

      {isMobile && isAuthenticated && <TabNavigation navigationItems={menus} />}
    </FlexDiv>
  );
};

export default Layout;
