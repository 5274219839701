import { Box, Typography, Container as UnstyledContainer } from "@mui/material";
import styled from '@mui/styled-engine';
import React, { useEffect, useState } from 'react';
import { useMount, useUnmount } from 'react-use';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import Config from "../../../../config";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { loadPersistedState } from '../../../utils/localStorage';
import ls from '../../../utils/localStorageKey';
import { authIsAdministratorSelector, authIsCustomerSelector, authTokenSelector } from '../../Auth/authSlice';
import { selectedFamilySelector } from "../../Families/familySlice";
import { patientsSelector, refreshPatients, selectedPatientsSelector } from '../../Patients/patientsSlice';
import { VideoPlaybackResult } from '../VideoPlaybackResult';
import { reportedPlaybacksSelector, saveVideoResult } from '../videoSlice';
import { VideoControls as UnstyledVideoControls } from './VideoControls';

// reference: https://gist.github.com/hamishrouse/4be2f37987cfe4af6a2c8a99e0ab5988

const Logo = styled("img")(({ theme }: any) => ({
  maxWidth: "100%",
  paddingBottom: theme.spacing(1),
}));

const VideoWrapper = styled("div")(() => ({
  "& video": {
    borderRadius: "0px",
  }
}));

const Title = styled(Box)(({ theme }: any) => ({
  padding: theme.spacing(1),
  position: "absolute",
  top: "0px",
  left: "0px",
  textShadow: "1px 1px 2px #656565",
  zIndex: 1001,
  color: "white",
}));

const Container = styled(UnstyledContainer)(({ theme }: any) => ({
  position: "relative",
  height: "100%",
}));

const VideoControls = styled(UnstyledVideoControls)(() => ({
  "& button": {
    color: "white !important",
    textShadow: "1px 1px 2px #656565",
  },

  position: "absolute",
  minWidth: "100px",
  right: "0px",
  bottom: "0px",
  zIndex: 1001,
}));


interface VideoPlayerPropsInferface {
  videoJsOptions: videojs.PlayerOptions;
  title?: string;
  patientNumber: string;
  isActive: boolean;
  nextAction?: () => void;
  fullScreenAction?: () => void;
  exitFullScreenAction?: () => void;
}

export const VideoPlayer = (props: VideoPlayerPropsInferface) => {
  let videoNode: HTMLVideoElement;
  const [player, setPlayer] = useState<videojs.Player>();
  const dispatch = useAppDispatch();
  const reportedPlaybacks = useAppSelector(reportedPlaybacksSelector);
  const isAdmin = useAppSelector(authIsAdministratorSelector);
  const isCustomer = useAppSelector(authIsCustomerSelector);
  const selectedPatient = useAppSelector(selectedPatientsSelector);
  const token = useAppSelector(authTokenSelector);
  const selectedFamily = useAppSelector(selectedFamilySelector);
  const auth = loadPersistedState(ls("auth"));
  const patients = useAppSelector(patientsSelector);

  useMount(() => {
    if (!props.isActive) {
      return;
    }
    const player = videojs(videoNode, props.videoJsOptions);
    player.ready(function () {
      setPlayer(this);
      player.on('waiting', waitHandler);
      player.on("playing", playHandler);
      player.on("error", errorHandler);

      if (props.exitFullScreenAction !== undefined) {
        player.enterFullWindow();
      }
    });
  });

  // destroy player on unmount
  useUnmount(() => {
    if (player) {
      player?.pause();
      player.dispose();
    }
  });

  useEffect(() => {
    if (!props.isActive) {
      player?.pause();
    }
  }, [props.isActive, player]);

  //Selected patient changed, so switch video stream
  useEffect(() => {
    if (selectedPatient?.wowza_stream_file && props.nextAction !== undefined) {
      if (player?.hasStarted) {
        player?.pause();
      }
      let url;
      console.log(selectedPatient.wowza_stream_file);
      if (selectedPatient.wowza_stream_file.startsWith("http")) {
        url = selectedPatient.wowza_stream_file;
      }
      else if (selectedPatient.stream_active) {
        url = Config.mediaUrl + "/" + selectedPatient.wowza_stream_file + Config.mediaSuffix + "/playlist.m3u8?t=" + token?.replace(/\./g, '___');
      }

      if (url !== undefined) {
        console.log({ url });
        const newSource = [{
          src: url,
          type: "application/x-mpegURL"
        }];
        player?.src(newSource);
        player?.pause();
        player?.load();
        player?.play();
      }
    }

  }, [selectedPatient, player, props.nextAction, token]);

  function playHandler(e: any) {
    if (!(isCustomer || isAdmin)) {
      logVideoResult(true, e.target.innerText);
    }
  }

  function waitHandler() {
    if (selectedPatient === undefined && selectedFamily?.community_id) {
      dispatch(refreshPatients(selectedFamily.community_id));
    }
  }

  function errorHandler(e: any) {
    if (!player?.hasStarted) {
      return;
    }
    if (patients.find(x => x.patient_number === props.patientNumber)?.stream_active === false) {
      return;
    }

    if (!(isCustomer || isAdmin)) {
      logVideoResult(false, e.target.innerText);
    }
  }

  function logVideoResult(success: boolean, message: string) {
    if (reportedPlaybacks.filter(x => x.patient_number === props.patientNumber &&
      x.success === success).length === 0) {
      const videoResult = {
        identity_id: auth.identityId,
        url: props.videoJsOptions.sources?.[0].src,
        patient_number: props.patientNumber,
        success: success,
        error_message: message,
      }
      dispatch(saveVideoResult(videoResult as VideoPlaybackResult));
    }
  }

  return (
    <Container>
      <VideoWrapper>
        <video muted className="video-js vjs-fluid vjs-fill player" ref={(node: HTMLVideoElement) => videoNode = node} playsInline />
      </VideoWrapper>

      <Title>
        <Typography variant="body1">
          {props.title}
        </Typography>
      </Title>


      <VideoControls
        nextAction={props.nextAction}
        exitFullScreenAction={props.exitFullScreenAction}
        fullScreenAction={props.fullScreenAction}
        isFullScreen={props.exitFullScreenAction !== undefined}
        allowPaging={props.nextAction !== undefined} />

    </Container>

  );
}
