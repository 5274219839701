import { PersonAdd } from "@mui/icons-material";
import { Grid, Tooltip, Button as UnstyledButton, Typography as UnstyledTypography } from "@mui/material";
import styled from '@mui/styled-engine';
import { GridColDef, GridEventListener, GridValueGetterParams, DataGrid as UnstyledDataGrid } from "@mui/x-data-grid";
import moment from "moment";
import "moment/locale/nl";
import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { MemberModal } from "../../components/MemberModal/MemberModal";
import { getSelectedLanguage } from "../../hooks/useLanguage";
import useTitle from "../../hooks/useTitle";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Member } from "../Members/Member";
import { setOpenMember } from "../Members/membersSlice";
import { fetchUsers, usersSelector } from "./userSlice";

const DataGrid = styled(UnstyledDataGrid)(({ theme }: any) => ({
  backgroundColor: theme.palette.background.paper,
}));

const Button = styled(UnstyledButton)(({ theme }: any) => ({
  textTransform: 'none',
  padding: theme.spacing(2, 2, 2),
}));

const ContainerGrid = styled(Grid)(() => ({
  height: isMobile ? "calc(100% - 8px)" : "100%",
  paddingLeft: isMobile ? "8px" : "108px",
  paddingRight: "8px",
  paddingBottom: "8px",
  flexDirection: "column",
  flexWrap: "nowrap",
}));

const TitleGrid = styled(Grid)(() => ({
  maxHeight: "50px",
}));

const Typography = styled(UnstyledTypography)(() => ({
  textTransform: "uppercase",
}));

export const NoRowsComponent = () => {
  const { t } = useTranslation();

  return (
    <div>{t("noMatch")}</div>
  );
}

export const UsersTable = () => {
  const { t } = useTranslation();

  const columns: GridColDef[] = [
    {
      headerName: t("name"),
      field: "first_name",
      flex: 1,
      valueGetter: (params: GridValueGetterParams<Member>) => {
        return `${params.row.first_name} ${params.row.last_name}`
      }
    },
    {
      headerName: t("email"),
      field: "email",
      flex: 1,
    },
    {
      headerName: t("role"),
      field: "role_name",
      flex: 1,
      valueGetter: (params: GridValueGetterParams<Member>) => {
        const user = params.row as Member;
        return t(user.role_name)
      }
    },
    {
      headerName: t("phonenumber"),
      field: "phonenumber",
      flex: 1,
    },
  ];
  const columnsMobile = columns.filter(x => x.field === 'first_name' || x.field === 'role');


  useTitle(t("Babywatch"));
  const dispatch = useAppDispatch();
  const selectedLanguage = getSelectedLanguage();
  const users = useAppSelector(usersSelector);

  const onRowClick: GridEventListener<'rowClick'> = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details, // GridCallbackDetails
  ) => {
    const user = params.row as Member;
    dispatch(setOpenMember(user));
  };

  useEffect(() => {
    moment.locale(selectedLanguage);
    dispatch(fetchUsers());
  }, [dispatch, selectedLanguage]);


  return (
    <ContainerGrid container>
      <TitleGrid item xs={12}>
        <Typography variant="h3" color="primary" gutterBottom>
          {t("administrators")}
        </Typography>
      </TitleGrid>
      <Grid item xs={12}>
        <DataGrid
          rows={users}
          columns={isMobile ? columnsMobile : columns}
          disableColumnFilter={isMobile}
          initialState={{
            sorting: {
              sortModel: [{ field: 'first_name', sort: 'asc' }],
            },
          }}
          pageSize={10}
          rowsPerPageOptions={[5, 10, 25]}
          disableSelectionOnClick
          onRowClick={onRowClick}
          components={{
            NoRowsOverlay: NoRowsComponent,
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ maxHeight: "50px" }}>
        <Tooltip title={t("add babywatchadministrator") || ''}>
          <Button onClick={() => dispatch(setOpenMember({ first_name: "", last_name: "", email: "", role_name: "BabywatchAdministrator" }))}
            startIcon={<PersonAdd color="primary" />}>
            {t("BabywatchAdministrator")}
          </Button>
        </Tooltip>
        <Tooltip title={t("add babywatchcustomer") || ''}>
          <Button onClick={() => dispatch(setOpenMember({ first_name: "", last_name: "", email: "", role_name: "BabywatchCustomer" }))}
            startIcon={<PersonAdd color="primary" />}>
            {t("BabywatchCustomer")}
          </Button>
        </Tooltip>
      </Grid>
      <MemberModal />
    </ContainerGrid>




  );
};
