import { Box, Grid, Typography, Container as UnstyledContainer, Paper as UnstyledPaper } from "@mui/material";
import styled from '@mui/styled-engine';
import moment from "moment";
import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import videojs from "video.js";
import Config from "../../../config";
import { Age } from "../../components/Format/Age";
import { GridWithTitle } from "../../components/Layout/GridWithTitle";
import { Loader } from "../../components/Loader/Loader";
import { getSelectedLanguage } from "../../hooks/useLanguage";
import useTitle from "../../hooks/useTitle";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { authTokenSelector } from "../Auth/authSlice";
import { selectedFamilySelector } from "../Families/familySlice";
import { fetchPatients, patientsIsLoadingSelector, patientsSelector, setSelectedPatient } from "../Patients/patientsSlice";
import { MessageType, VideoMessage } from "./Components/VideoMessage";
import { VideoPlayer } from "./Components/VideoPlayer";
import { Video } from "./Video";
import "./skin.css";

const Container = styled(UnstyledContainer)(({ theme }: any) => ({
  margin: isMobile ? theme.spacing(0, 0, 10) : theme.spacing(0, 1, 10),
  maxWidth: isMobile ? theme.spacing(100) : theme.spacing(170),
}));

const Item = styled(Grid)(() => ({
  padding: isMobile ? "0px!important" : "5px!important",
  paddingBottom: "5px!important",
}));

const ItemContent = styled(Box)(() => ({
  height: "100%",
}));

const Paper = styled(UnstyledPaper)(({ theme }: any) => ({
  padding: isMobile ? theme.spacing(1) : theme.spacing(2),
  borderRadius: "0.5rem",
  height: "100%",


  display: "flex",
  flexDirection: "column",



}));

const Subtitle = styled(Grid)(() => ({
  fontSize: "0.7rem",
}));

const Body = styled(Grid)(() => ({
  fontSize: "0.7rem",
  fontWeight: "bold",
}));

export const VideoList = () => {
  const { t } = useTranslation();
  useTitle(t("BabyWatch") + " - " + t("video"));
  const dispatch = useAppDispatch();
  const selectedLanguage = getSelectedLanguage();
  const patients = useAppSelector(patientsSelector);
  const isLoading = useAppSelector(patientsIsLoadingSelector);
  const token = useAppSelector(authTokenSelector);
  const selectedFamily = useAppSelector(selectedFamilySelector);

  //Language changed
  useEffect(() => {
    moment.locale(selectedLanguage);
  }, [selectedLanguage]);

  //Community changed so refresh patients
  useEffect(() => {
    if (selectedFamily?.community_id) {
      dispatch(fetchPatients(selectedFamily.community_id));
    }
  }, [dispatch, selectedFamily]);

  //On Mobile to recognize landscape mode change
  useEffect(() => {
    if (!isMobile) {
      return;
    }
    const orientationLandscapeCheck = window.matchMedia("(orientation: landscape)");
    orientationLandscapeCheck.addEventListener('change', getOrientationValue);
    // this is the cleanup function to remove the listener
    return () => orientationLandscapeCheck.removeEventListener('change', getOrientationValue);
  });

  function getOrientationValue(mediaQueryList: MediaQueryListEvent) {
    const toLandscape = mediaQueryList.matches;
    if (toLandscape === true) {
      dispatch(setSelectedPatient(patients[0]));
    }
    else {
      dispatch(setSelectedPatient(undefined));
    }
  }

  const videoJsOptions: videojs.PlayerOptions = {
    autoplay: true,
    controls: false,
    loop: true,
    muted: true,
    preload: 'auto',
    controlBar: {
      fullscreenToggle: true,
    },
  };

  const patientStates = patients.map((patient) => {
    if (patient.state === "admitted") {
      return (patient.stream_active || (patient.wowza_stream_file?.startsWith("http"))) ? "active" : "busy";
    } else {
      return patient.state;
    }
  });

  return (
    <GridWithTitle title="Video">
      <Container>
        <Loader isLoading={isLoading} />
        <Grid container>
          {
            patients.length > 0 ? patients.map((patient, index) => (
              <Item item key={patient.id} xs={12} sm={12} md={12} lg={6} xl={6}>
                <ItemContent ml={isMobile ? 0 : 2} mb={1}>
                  <Paper>
                    {patientStates[index] === "active" ? <VideoPlayer videoJsOptions={
                      {
                        ...videoJsOptions, sources:
                          [{
                            src: patient.wowza_stream_file.startsWith("http") ? patient.wowza_stream_file : Config.mediaUrl + "/" + patient.wowza_stream_file + Config.mediaSuffix + "/playlist.m3u8?t=" + token?.replace(/\./g, '___'),
                            type: "application/x-mpegURL"
                          }]
                      }} patientNumber={patient.patient_number}
                      fullScreenAction={() => dispatch(setSelectedPatient(patient))}
                      isActive={patient.wowza_stream_file.startsWith("http") ? true : patient.stream_active} />
                      : <VideoMessage
                        messageType={patientStates[index] === "busy" ? MessageType.Busy : MessageType.Discharged}
                        fullScreenAction={() => dispatch(setSelectedPatient(patient))} />
                    }
                    <Box p={2}>
                      <Typography variant="h5" color="primary">
                        {patient.first_name}
                      </Typography>

                      <Grid container>
                        <Subtitle item xs={5}>
                          {t("date_of_birth")}
                        </Subtitle>
                        <Body item xs={7}>
                          <Moment date={new Date(patient.date_of_birth)} format="dddd DD MMMM YYYY" locale={selectedLanguage} />
                        </Body>
                        {patient.state === 'admitted' && <>
                          <Subtitle item xs={5}>
                            {t("age")}
                          </Subtitle>
                          <Body item xs={7}>
                            <Age date={patient.date_of_birth} />
                          </Body>
                        </>}
                      </Grid>
                    </Box>
                  </Paper>
                </ItemContent>
              </Item>
            )) : <div>{t("unauthorized")}</div>
          }
        </Grid>
        <Video />
      </Container>
    </GridWithTitle>

  );
};
