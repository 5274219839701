import { List, ListItemText, Paper, IconButton as UnstyledIconButton, MenuItem as UnstyledMenuItem, Drawer as UnstyledMuiDrawer } from "@mui/material";
import { darken, useTheme } from "@mui/material/styles";
import styled from '@mui/styled-engine';
import React, { FunctionComponent } from "react";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { NavigationMenu } from "../../NavigationMenu";

const drawerWidth = 100;

const MuiDrawer = styled(UnstyledMuiDrawer)`
  width: drawerWidth;
  flexShrink: 0;
  whiteSpace: "nowrap";
`

const StyledPaper = styled(Paper)(({ theme }: any) => ({
  width: drawerWidth,
  background: theme.palette.primary.main,
  paddingTop: theme.spacing(2),
  color: theme.palette.primary.contrastText,
}));

const Toolbar = styled("div")(({ theme }: any) => ({
  ...theme.mixins.toolbar
}));

const MenuItem = styled(UnstyledMenuItem)(({ theme }: any) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  primary: darken(theme.palette.primary.contrastText, 0.2)
}));

const IconButton = styled(UnstyledIconButton)(({ theme }: any) => ({
  color: darken(theme.palette.primary.contrastText, 0.2)
}));
const SelectedIconButton = styled(UnstyledIconButton)(({ theme }: any) => ({
  color: theme.palette.primary.contrastText
}));

const Drawer: FunctionComponent<{
  show: boolean;
  menus: NavigationMenu[];
}> = ({ show, menus }) => {
  const theme = useTheme();

  return (
    <nav aria-label="navigation menu" className="no-print">
      {show && (
        <MuiDrawer
          variant="permanent"
          // PaperProps={{ component: StyledPaper }}
          PaperProps={{
            sx: {
              width: drawerWidth,
              background: theme.palette.primary.main,
              paddingTop: theme.spacing(2),
              color: theme.palette.primary.contrastText,
            }
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Toolbar />

          <List dense={!isMobile}>
            {menus.map((menu, index) => (
              <MenuItem component={Link} to={menu.url} key={menu.title} selected={window.location.pathname.includes(menu.url)}>
                {window.location.pathname.includes(menu.url) &&
                  (<><SelectedIconButton size="large">
                    {menu.icon}
                  </SelectedIconButton>
                    <ListItemText primary={menu.title} primaryTypographyProps={{ variant: "inherit" }} /> </>)
                }
                {!window.location.pathname.includes(menu.url) &&
                  (<><IconButton size="large">
                    {menu.icon}
                  </IconButton>
                    <ListItemText primary={menu.title} primaryTypographyProps={{ variant: "inherit" }} /> </>)
                }
              </MenuItem >
            ))}
          </List>
        </MuiDrawer>
      )}
    </nav>
  );
};

export default Drawer;
