import { Tooltip } from "@mui/material";
import styled from '@mui/styled-engine';
import React from "react";
import { useTranslation } from "react-i18next";
import { default as netherlands } from "../../assets/netherlands.svg";
import { default as unitedKingdom } from "../../assets/united-kingdom.svg";
import { getSelectedLanguage, Language, LanguageName, setSelectedLanguage } from "../../hooks/useLanguage";

const FlagBase = styled("img")(({ theme }: any) => ({
  width: theme.spacing(4),
  transition: theme.transitions.create(["filter", "opacity"]),
  cursor: "pointer",
  "&:hover": {
    filter: "none",
    opacity: 1,
  },
  marginLeft: theme.spacing(1),
}));

const Flag = styled(FlagBase)(() => ({
  filter: "grayscale(100%)",
  opacity: 0.8,
}));

const ActiveFlag = styled(FlagBase)(() => ({
  filter: "none",
  opacity: 1.0,
}));

interface LanguageSelectPropsInterface {
  onChange: (language: string) => void;
}

const LanguageSelect = (props: LanguageSelectPropsInterface) => {

  const selectedLanguage = getSelectedLanguage();
  const changeLanguage = setSelectedLanguage;

  const { t } = useTranslation();
  const languages: Language[] = [
    { title: t("Dutch"), name: "nl", flag: netherlands },
    { title: t("English"), name: "en", flag: unitedKingdom },
  ];

  const handleLanguageSelect = (languageName: LanguageName) => {
    props.onChange(languageName);
    changeLanguage(languageName);
  }

  return (
    <div>
      {languages.map((language) => (
        <Tooltip key={language.name} title={language.title}>
          <span>
            {
              (selectedLanguage === language.name ?
                <ActiveFlag
                  src={language.flag}
                  alt={language.title}
                  onClick={() => { handleLanguageSelect(language.name); }}
                />
                :
                <Flag
                  src={language.flag}
                  alt={language.title}
                  onClick={() => { handleLanguageSelect(language.name); }}
                />
              )
            }
          </span>
        </Tooltip>
      ))}
    </div>
  );
};

export default LanguageSelect;
