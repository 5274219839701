import { ErrorOutline } from "@mui/icons-material";
import { Badge, IconButton, Tooltip as UnstyledTooltip } from "@mui/material";
import styled from '@mui/styled-engine';
import React, { FunctionComponent, useEffect } from "react";
import { authUserSelector } from "../../domain/Auth/authSlice";
import { fetchNotifications, notificationsSelector } from "../../domain/Notifications/notificationsSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

const Tooltip = styled(UnstyledTooltip)(({ theme }: any) => ({
  color: theme.palette.primary.contrastText,
}));

export const NotificationIcon: FunctionComponent<unknown> = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(authUserSelector);
  const notifications = useAppSelector(notificationsSelector);

  useEffect(() => {
    if (user !== undefined) {
      dispatch(fetchNotifications(user.identity_id));
    }
  }, [dispatch, user]);

  return (notifications && notifications.length > 0) ?
    <Tooltip title={(notifications && notifications.length > 0) ? notifications[0].message : ''}>
      <IconButton edge="start" size="large">
        <Badge badgeContent={notifications?.length} color="error">
          <ErrorOutline />
        </Badge>
      </IconButton>
    </Tooltip>
    : <> </>;
};
