import { faCamera as Camera, faCircle as Circle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowBack } from "@mui/icons-material";
import { AppBar, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Toolbar, Typography, Avatar as UnstyledAvatar, FormControl as UnstyledFormControl, TextField as UnstyledTextField } from "@mui/material";
import styled from '@mui/styled-engine';
import { Form, Formik, FormikProps } from 'formik';
import "moment/locale/nl";
import React, { useEffect, useRef } from "react";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { useToggle } from "react-use";
import { AvatarEditorModal } from "../../components/AvatarEditorModal/AvatarEditorModal";
import LanguageSelect from "../../components/LanguageSelect/LanguageSelect";
import { SlideOrZoomTransition } from "../../components/Transition/Transition";
import useTitle from "../../hooks/useTitle";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { User } from '../Auth/Auth';
import { selectedFamilySelector } from "../Families/familySlice";
import SmsVerificationDialog from "./Components/SmsVerificationDialog";
import { closeOpenSettingsUser, setOpenSmsVerification, settingsUserSelector, showAvatarEditor, updateUser, validateUser } from "./settingsSlice";

const MenuButton = styled(IconButton)(({ theme }: any) => ({
  marginRight: theme.spacing(2),
}));

const Title = styled(Typography)(({ theme }: any) => ({
  flexGrow: 1,
}));

const Spacing = styled("div")(({ theme }: any) => ({
  paddingTop: theme.spacing(7),
}));

const TextField = styled(UnstyledTextField)(({ theme }: any) => ({
  marginTop: theme.spacing(0.5),
}));

const Avatar = styled(UnstyledAvatar)(({ theme }: any) => ({
  marginTop: theme.spacing(0.5),
  width: theme.spacing(10),
  height: theme.spacing(10),
  cursor: "pointer"
}));

const FormControl = styled(UnstyledFormControl)(({ theme }: any) => ({
  marginTop: theme.spacing(0.5),
}));

const FormControlCentered = styled(FormControl)(({ theme }: any) => ({
  alignItems: 'center',
}));

const SelectLanguageLabel = styled(Typography)(({ theme }: any) => ({
  marginTop: theme.spacing(0.5),
}));

const AvatarEditIcon = styled(FontAwesomeIcon)(({ theme }: any) => ({
  position: 'absolute',
  top: "70px",
  marginLeft: "60px",
  cursor: "pointer"
}));

const AvatarEditCircleIcon = styled(FontAwesomeIcon)(({ theme }: any) => ({
  position: 'absolute',
  top: "65px",
  marginLeft: "60px",
  cursor: "pointer"
}));

export const Settings = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  useTitle(t("BabyWatch") + " - " + t("settings"));

  const handleFormSubmit = async (user: User) => {
    const updatedUser = user;
    const validUser = await validateUser(updatedUser.identity_id, updatedUser.email);
    if (validUser === false) {
      toast.error(t("emailExists"));
      return;
    }
    if (updatedUser.phonenumber !== originalPhoneNumber || updatedUser.email !== originalEmail) {
      dispatch(setOpenSmsVerification(updatedUser));
      return;
    }
    updateAndCloseSettings(updatedUser);
  };

  const updateAndCloseSettings = (updatedUser: User) => {
    if (!settingsUser) {
      return;
    }
    if (updatedUser.identity_id) {
      dispatch(updateUser(updatedUser.identity_id, updatedUser));
    }
    closeSettings();
  };


  const closeSettings = () => {
    dispatch(closeOpenSettingsUser());
  };

  const settingsUser = useAppSelector(settingsUserSelector);
  const originalPhoneNumber = settingsUser?.phonenumber;
  const originalEmail = settingsUser?.email;
  const formRef = useRef<FormikProps<User>>(null);
  const [showDialog, setShowDialog] = useToggle(settingsUser !== undefined);
  const dialogPadding = isMobile ? "0px" : "16px";
  const selectedFamily = useAppSelector(selectedFamilySelector);

  const isParent = !!settingsUser?.communities?.find(c => c.community_id === selectedFamily?.community_id && c.is_parent);

  useEffect(() => {
    setShowDialog(!!settingsUser?.identity_id);
  }, [setShowDialog, settingsUser]);

  return (
    <Dialog fullScreen={isMobile} open={showDialog} aria-labelledby="form-dialog-title" id="modal-add"
      TransitionComponent={SlideOrZoomTransition}
      PaperProps={{
        style: {
          padding: dialogPadding,
          boxShadow: 'none',
        },
      }}>
      <Formik innerRef={formRef} initialValues={{
        admin: 0, email: "", family_id: 0, first_name: "", id: 0, identity_id: "",
        language: "", last_name: "", phonenumber: "", password: "", profile_image: "", remember_token: null, role_name: "", username: "", avatar: { content: "" }, communities: [], deactivated_communities: [], ...settingsUser
      }}
        onSubmit={handleFormSubmit} autoComplete="off" enableReinitialize>
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            <BrowserView>
              <DialogTitle id="form-dialog-title">{t("settings")}</DialogTitle>
            </BrowserView>
            <MobileView>
              <AppBar position="fixed" elevation={0}>
                <Toolbar>
                  <MenuButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={closeSettings}
                    size="large">
                    <ArrowBack />
                  </MenuButton>
                  <Title variant="h6">
                    {t("settings")}
                  </Title>
                </Toolbar>
              </AppBar>
              <Spacing />
            </MobileView>

            <DialogContent>
              <FormControlCentered
                variant="standard"
                fullWidth>
                <Avatar src={values.avatar?.content ? `data:image/jpeg;base64,${values.avatar.content}` : ''}
                  onClick={() => dispatch(showAvatarEditor(values.avatar))} />
                <AvatarEditCircleIcon icon={Circle} color="#47446b" size="2x" onClick={() => dispatch(showAvatarEditor(values.avatar))} />
                <AvatarEditIcon icon={Camera} color="white" onClick={() => dispatch(showAvatarEditor(values.avatar))} />
              </FormControlCentered>
              <FormControl fullWidth variant="filled">
                <TextField
                  variant="standard"
                  id="input-first-name"
                  label={t("firstname")}
                  value={values.first_name || ""}
                  onChange={handleChange("first_name")} />
              </FormControl>
              <FormControl fullWidth variant="filled">
                <TextField
                  variant="standard"
                  id="input-last-name"
                  label={t("lastname")}
                  value={values.last_name || ""}
                  onChange={handleChange("last_name")} />
              </FormControl>
              <FormControl fullWidth variant="filled">
                <TextField
                  variant="standard"
                  id="input-email"
                  label={t("email")}
                  value={values.email || ""}
                  onChange={handleChange("email")} />
              </FormControl>
              <FormControl fullWidth variant="filled">
                <TextField
                  variant="standard"
                  id="input-phonenumber"
                  label={t("phonenumber")}
                  value={values.phonenumber || ""}
                  onChange={handleChange("phonenumber")} />
              </FormControl>
              <FormControl fullWidth variant="filled">
                <TextField
                  variant="standard"
                  label={`${t("role")}:`}
                  value={isParent ? t("Parent") : t("Friend")}
                  disabled />
              </FormControl>
              <FormControlCentered fullWidth variant="filled">
                <SelectLanguageLabel>
                  {t("selectlanguage")}
                </SelectLanguageLabel>
                <LanguageSelect onChange={(value: string) => setFieldValue("language", value)} />
              </FormControlCentered>
              <FormControl fullWidth variant="filled">
              </FormControl>
            </DialogContent>

            <DialogActions>
              <BrowserView>
                <Button color="primary" type="button" onClick={closeSettings}>
                  {t("cancel")}
                </Button>
              </BrowserView>
              <Button variant="contained" id="button-save" color="primary" type="submit">
                {t("save")}
              </Button>
            </DialogActions>
            <SmsVerificationDialog onConfirmAction={updateAndCloseSettings} />

          </Form>)}
      </Formik>

      <AvatarEditorModal />

    </Dialog>
  );
};
