import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { User } from '../../Auth/Auth';
import { openSmsVerificationSelector, sendSmsCode, setOpenSmsVerification, setVerificationCode, validateSmsCode } from '../settingsSlice';

interface SmsVerificationDialogPropsInterface {
  onConfirmAction: (user: User) => void;
}

export default function SmsVerificationDialog(props: Readonly<SmsVerificationDialogPropsInterface>) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [token, setToken] = useState("");
  const [error, setError] = useState("");
  const user = useAppSelector(openSmsVerificationSelector);

  const handleClose = () => {
    dispatch(setOpenSmsVerification(undefined));
  };

  const handleVerify = async () => {
    if (user === undefined) {
      return;
    }
    const valid = user?.identity_id && (await validateSmsCode(user.identity_id, token));
    console.log({ valid });
    if (valid) {
      batch(() => {
        dispatch(setVerificationCode(token));
        dispatch(setOpenSmsVerification(undefined));
        props.onConfirmAction(user);
      });
    }
    else {
      setError(t("Invalid code"));
    }
  };

  useEffect(() => {
    setError("");
    setToken("");
    if (user?.identity_id) {
      sendSmsCode(user?.identity_id);
    }
  }, [user]);


  return (
    <div>
      <Dialog open={user?.identity_id !== undefined} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t("smsVerificationTitle")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("smsVerificationText")}
          </DialogContentText>
          <TextField
            variant="standard"
            autoFocus
            margin="dense"
            id="name"
            label="Sms code"
            type="number"
            fullWidth
            helperText={error}
            error={error !== ""}
            onChange={e => setToken(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("cancel")}
          </Button>
          <Button onClick={handleVerify} color="primary" variant="contained">
            {t("continue")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
