import { Typography, Box as UnstyledBox, Container as UnstyledContainer } from "@mui/material";
import styled from '@mui/styled-engine';
import "moment/locale/nl";
import React from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import discharged from '../../../assets/discharged.png';
import treatment from '../../../assets/treatment.png';
import { VideoControls as UnstyledVideoControls } from "./VideoControls";

const Container = styled(UnstyledContainer)(({ theme }: any) => ({
  backgroundColor: "#efefef",
  padding: theme.spacing(0),
  height: "100%",
  position: "relative",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const Picture = styled("img")(() => ({
  maxHeight: isMobile ? "100px" : "200px",
  width: "auto",
  height: "auto",
}));

const Message = styled(Typography)(() => ({
  fontWeight: 600,
}));

const VideoControls = styled(UnstyledVideoControls)(() => ({
  position: "absolute",
  bottom: 0,
  right: 0,
}));

const VideoControlsFullScreen = styled(UnstyledVideoControls)(() => ({
  position: "absolute",
  bottom: 0,
  right: 0,
}));

const MessageBox = styled(UnstyledBox)(({ theme }: any) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  paddingBottom: "0.4em",
}));


interface VideoMessagePropsInferface {
  messageType: MessageType;
  nextAction?: any;
  fullScreenAction?: any;
  exitFullScreenAction?: any;
}

export enum MessageType {
  Busy,
  Discharged
}

export const VideoMessage = (props: VideoMessagePropsInferface) => {

  const { t } = useTranslation();

  return (
    <Container>
      <MessageBox>
        <Picture
          src={props.messageType === MessageType.Discharged ? discharged : treatment}
          alt="treatment"
        />
        <Message color="primary">
          {props.messageType === MessageType.Discharged ? t("Patient is discharged") : t("Treatment in progress")}
        </Message>
      </MessageBox>
      {props.exitFullScreenAction ?
        <VideoControlsFullScreen
          nextAction={() => props.nextAction ? props.nextAction() : undefined}
          exitFullScreenAction={() => props.exitFullScreenAction ? props.exitFullScreenAction() : undefined}
          fullScreenAction={() => props.fullScreenAction ? props.fullScreenAction() : undefined}
          isFullScreen={props.exitFullScreenAction !== undefined}
          allowPaging={props.nextAction !== undefined} />
        :
        <VideoControls
          nextAction={() => props.nextAction ? props.nextAction() : undefined}
          exitFullScreenAction={() => props.exitFullScreenAction ? props.exitFullScreenAction() : undefined}
          fullScreenAction={() => props.fullScreenAction ? props.fullScreenAction() : undefined}
          isFullScreen={props.exitFullScreenAction !== undefined}
          allowPaging={props.nextAction !== undefined} />
      }
    </Container>
  );
};
