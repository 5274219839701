import { ArrowBack } from "@mui/icons-material";
import { AppBar, Dialog, DialogContent, Toolbar, Typography } from "@mui/material";
import UnstyledDialogTitle from "@mui/material/DialogTitle";
import UnstyledIconButton from "@mui/material/IconButton";
import styled from '@mui/styled-engine';
import "moment/locale/nl";
import React from "react";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { hideAvatarEditor, selectedAvatarSelector, setAvatar, showAvatarEditorSelector } from "../../domain/Settings/settingsSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import AvatarEditor from "../AvatarEditor/AvatarEditor/AvatarEditor";
import { SlideOrZoomTransition } from "../Transition/Transition";

const DialogTitle = styled(UnstyledDialogTitle)`
  alignSelf: "center";
  color: theme.palette.primary.dark;
`

const IconButton = styled(UnstyledIconButton)(({ theme }: any) => ({
  marginRight: theme.spacing(2)
}));

const Title = styled(Typography)(({ theme } : any) => ({
  flexGrow: 1,
}));

const Spacing = styled("div")(({ theme }: any) => ({
  paddingTop: theme.spacing(7)
}));

export const AvatarEditorModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const dialogPadding = isMobile ? "0px" : "16px";
  const title = t("Profile image");

  const selectedAvatar = useAppSelector(selectedAvatarSelector);
  const isVisible = useAppSelector(showAvatarEditorSelector);

  const handleSubmit = (image: string) => {
    if (image === undefined) {
      return;
    }
    dispatch(setAvatar({ content: image }));
    dispatch(hideAvatarEditor());
  }

  const handleClose = () => {
    dispatch(hideAvatarEditor());
  }

  return (
    <Dialog fullScreen={isMobile} open={isVisible} aria-labelledby="form-dialog-title" id="modal-add"
      TransitionComponent={SlideOrZoomTransition}
      PaperProps={{
        style: {
          padding: dialogPadding,
          boxShadow: 'none',
        },
      }}>
      <BrowserView>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      </BrowserView>
      <MobileView>
        <AppBar position="fixed" elevation={0}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleClose}
              size="large">
              <ArrowBack />
            </IconButton>
            <Title variant="h6">
              {title}
            </Title>
          </Toolbar>
        </AppBar>
        <Spacing />
      </MobileView>

      <DialogContent>
        <AvatarEditor
          forceImage={`data:image/jpeg;base64,${selectedAvatar?.content}`}
          onUpdateImage={handleSubmit}
          onClose={handleClose}
          validAttachmentTypes={{
            'image/*': ['.png', '.gif', '.jpeg', '.jpg']
          }}
        />
      </DialogContent>
    </Dialog>
  );
};
