import { People, PermDeviceInformation } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import styled from '@mui/styled-engine';
import { GridColDef, GridEventListener, GridRenderCellParams, GridRowParams, GridValueFormatterParams, DataGrid as UnstyledDataGrid } from "@mui/x-data-grid";
import moment from "moment";
import "moment/locale/nl";
import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { GridWithTitle } from "../../../components/Layout/GridWithTitle";
import { getSelectedLanguage } from "../../../hooks/useLanguage";
import useTitle from "../../../hooks/useTitle";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Family } from "../Family";
import { familiesWithDetailsSelector, fetchFamiliesWithDetails, setSelectedFamilyFromList } from "../familySlice";
import { FamilyDetail } from "./FamilyDetail";

const DataGrid = styled(UnstyledDataGrid)(({ theme }: any) => ({
  backgroundColor: theme.palette.background.paper,
}));

export const FamiliesTable = () => {
  const { t } = useTranslation();

  const columns: GridColDef[] = [
    {
      field: "family_name",
      headerName: t("family"),
      flex: 2,
    },
    {
      field: "member_count",
      headerName: t("members"),
      flex: 1,
    },
    {
      field: "chat_count",
      headerName: t("chat"),
      flex: 1,
    },
    {
      field: "desktop_count",
      headerName: t("desktops"),
      flex: 1,
    },
    {
      field: "tablet_count",
      headerName: t("tablets"),
      flex: 1,
    },
    {
      field: "mobile_count",
      headerName: t("mobiles"),
      flex: 1,
    },
    {
      field: "last_login",
      headerName: t("last login"),
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        return params.value === null ? '' : moment(params.value).format('DD-MM-YY HH:mm')
      }
    },
    {
      field: "video_playback_success_percentage",
      headerName: t("video success"),
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        return `${params.value === -1 ? '' : params.value + ' %'}`;
      }
    },
    {
      field: "id",
      headerName: t("actions"),
      flex: 1,
      renderCell: (params: GridRenderCellParams<number, FamilyDetail>) => {
        const family = params.row;

        return <>
          {
            family.video_playback_success_percentage > -1 &&
            <IconButton size="small" title={t("memberdevices")} onClick={() => onDevicesClick(family)}><PermDeviceInformation /></IconButton>

          }
          <IconButton size="small" title={t("family")} onClick={() => onDetailClick(family)}><People /></IconButton>
        </>
      }
    },
  ];

  const columnsMobile = columns.filter(x => x.field === 'family_name' || x.field === 'video_playback_success_percentage' || x.field === 'id');

  useTitle(t("Babywatch"));
  const dispatch = useAppDispatch();
  const selectedLanguage = getSelectedLanguage();
  const families = useAppSelector(familiesWithDetailsSelector);
  const navigate = useNavigate();

  const onRowClick: GridEventListener<'rowClick'> = (
    params: GridRowParams,
  ) => {
    const family = params.row as Family;
    dispatch(setSelectedFamilyFromList(family));
    navigate("/devices");
  };

  const onDevicesClick = (family: FamilyDetail) => {
    dispatch(setSelectedFamilyFromList(family as Family));
    navigate('/devices');
  }

  const onDetailClick = (family: FamilyDetail) => {
    dispatch(setSelectedFamilyFromList(family as Family));
    navigate('/family');
  }

  useEffect(() => {
    moment.locale(selectedLanguage);
    dispatch(fetchFamiliesWithDetails());
  }, [dispatch, selectedLanguage]);


  return (
    <GridWithTitle title={t("families")}>
      <DataGrid
        rows={families}
        columns={isMobile ? columnsMobile : columns}
        disableColumnFilter={isMobile}
        initialState={{
          sorting: {
            sortModel: [{ field: 'family_name', sort: 'asc' }],
          },
        }}
        pageSize={10}
        rowsPerPageOptions={[5, 10, 25]}
        disableSelectionOnClick
        onRowClick={onRowClick}
      />
    </GridWithTitle>
  );
};
