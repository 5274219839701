import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { batch } from "react-redux";
import { AppThunk, RootState } from "../../store/store";
import { httpClient } from "../../utils/httpClient";

interface NotActiveState {
  isLoading: boolean;
  errorMessage: string | undefined;
  downloadAvailable: boolean;
  isParentInThisCommunity: boolean | undefined;
}

const initialState: NotActiveState = {
  isLoading: false,
  errorMessage: undefined,
  downloadAvailable: false,
  isParentInThisCommunity: false,
}

export const notActiveSlice = createSlice({
  name: "notActive",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<NotActiveState["isLoading"]>) {
      state.isLoading = action.payload;
    },
    setErrorMessage(state, action: PayloadAction<NotActiveState["errorMessage"]>) {
      state.errorMessage = action.payload;
    },
    setDownloadAvailable(state, action: PayloadAction<NotActiveState["downloadAvailable"]>) {
      state.downloadAvailable = action.payload;
    },
    setIsParentInThisCommunity(state, action: PayloadAction<NotActiveState["isParentInThisCommunity"]>) {
      state.isParentInThisCommunity = action.payload;
    },
  },
});

export const notActiveReducer =  notActiveSlice.reducer;

export const { setLoading, setErrorMessage, setDownloadAvailable, setIsParentInThisCommunity } = notActiveSlice.actions;

export const fetchDownload = (communityId: any): AppThunk => async (dispatch) => {
  batch(() => {
    dispatch(setLoading(true));
    dispatch(setErrorMessage(undefined));
  });

  httpClient.get(`Community/${encodeURIComponent(communityId)}/Diary`, false, true).then((response) => {
    const blob = response.data;
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  }).catch((error) => {
    console.error("Error while downloading file: ", error)
  });
};

export const getDownloadAvailable = (state: RootState) => state.notActive.downloadAvailable;
export const getParentInThisCommunity = (state: RootState) => state.notActive.isParentInThisCommunity;