import { Avatar, IconButton, Popover, Toolbar } from "@mui/material";
import UnstyledAppBar from "@mui/material/AppBar";
import UnstyledBox from "@mui/material/Box";
import styled from '@mui/styled-engine';
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import React, { FunctionComponent, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { authIsAuthenticatedSelector, authUserSelector } from "../../domain/Auth/authSlice";
import { useAppSelector } from "../../store/hooks";
import { NavigationMenu } from "../NavigationMenu";
import { NotificationIcon } from "../NotificationIcon/NotificationIcon";
import { UserMenu } from "../UserMenu/UserMenu";
import { useAppHeaderSelector } from "./appHeaderSlice";

const AppBar = styled(UnstyledAppBar)(({ theme }: any) => ({
  zIndex: theme.zIndex.drawer + 1
}));

const Box = styled(UnstyledBox)(() => ({
  flexGrow: 1,
  cursor: "pointer"
}));

const Title = styled("span")(({ theme }: any) => ({
  ...theme.typography.h1,
  textTransform: "uppercase",
  fontSize: "1.5rem",
  letterSpacing: "0.1rem",
}));

const BoldTitle = styled(Title)`
  fontWeight: 700;
`

const SizedAvatar = styled(Avatar)(({ theme }: any) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
}));

export const AppHeader: FunctionComponent<{
  navigationItems: NavigationMenu[];
}> = ({ navigationItems }) => {
  const showAppHeader = useAppSelector(useAppHeaderSelector);
  const isAuthenticated = useAppSelector(authIsAuthenticatedSelector);
  const authUser = useAppSelector(authUserSelector);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | undefined>(undefined);
  const appBarRef = React.useRef<HTMLDivElement>();
  const navigate = useNavigate();

  useEffect(() => {
    setAnchorEl(appBarRef.current);
  }, [appBarRef]);

  return (
    <Box display={showAppHeader ? "" : "none"}>
      <AppBar ref={appBarRef} position="fixed" elevation={0}>
        <Toolbar>
          <Box onClick={() => navigate("/login")}>
            <Title>Baby</Title>
            <BoldTitle>watch</BoldTitle>
          </Box>

          {isAuthenticated && (
            <>
              <NotificationIcon />
              <PopupState variant="popover" popupId="usermenu-popup-popover">
                {(popupState) => (
                  <>
                    <IconButton
                      id="button-profile"
                      color="inherit"
                      {...bindTrigger(popupState)}
                      size="large">
                      <SizedAvatar src={authUser?.avatar?.content ? `data:image/jpeg;base64,${authUser.avatar.content}` : ``} />
                    </IconButton>
                    <Popover
                      {...bindPopover(popupState)}
                      anchorReference={isMobile ? "anchorEl" : undefined}
                      anchorEl={anchorEl}
                      anchorOrigin={
                        isMobile
                          ? {
                            vertical: "bottom",
                            horizontal: "center",
                          }
                          : {
                            vertical: "bottom",
                            horizontal: "right",
                          }
                      }
                      transformOrigin={
                        isMobile
                          ? {
                            vertical: "top",
                            horizontal: "center",
                          }
                          : {
                            vertical: "top",
                            horizontal: "right",
                          }
                      }
                    >
                      <UserMenu popupState={popupState} />
                    </Popover>
                  </>
                )}
              </PopupState>
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};
