import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { batch } from "react-redux";
import { toast } from 'react-toastify';
import { AppThunk, RootState } from "../../store/store";
import { httpClient } from "../../utils/httpClient";
import { Family } from "./Family";
import { FamilyDetail } from "./Tables/FamilyDetail";

interface FamilyState {
  isLoading: boolean;
  errorMessage: string | undefined;
  families: Family[];
  familiesWithDetail: FamilyDetail[];
  selectedFamily: Family | undefined
}

const initialState: FamilyState = {
  isLoading: false,
  errorMessage: undefined,
  families: [],
  familiesWithDetail: [],
  selectedFamily: undefined
};

export const familySlice = createSlice({
  name: "members",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<FamilyState["isLoading"]>) {
      state.isLoading = action.payload;
    },
    setErrorMessage(state, action: PayloadAction<FamilyState["errorMessage"]>) {
      state.errorMessage = action.payload;
    },
    setFamilies(state, action: PayloadAction<FamilyState["families"]>) {
      state.families = action.payload;
    },
    setFamiliesWithDetails(state, action: PayloadAction<FamilyState["familiesWithDetail"]>) {
      state.familiesWithDetail = action.payload;
    },
    setSelectedFamily(state, action: PayloadAction<FamilyState["selectedFamily"]>) {
      state.selectedFamily = action.payload;
    },
  },
});

export const familiesReducer = familySlice.reducer;

const { setLoading, setErrorMessage, setFamilies, setFamiliesWithDetails, setSelectedFamily } = familySlice.actions;

export const setSelectedFamilyFromList = (family: Family): AppThunk => async (dispatch) => {
  dispatch(setSelectedFamily(family));
}

export const fetchFamily = (communityId: string): AppThunk => async (dispatch) => {
  batch(() => {
    dispatch(setLoading(true));
    dispatch(setErrorMessage(undefined));
  });
  httpClient.get(`family/${encodeURIComponent(communityId)}`)
    .then((response) => {
      batch(() => {
        dispatch(setSelectedFamily(response.data));
        dispatch(setLoading(false));
      });
    })
    .catch((error) => {
      batch(() => {
        dispatch(setLoading(false));
        dispatch(setErrorMessage("There was an error while trying to fetch the members list"));
        toast.error(error.response?.data.message || error.message);
      });
    });
};

export const fetchFamilies = (): AppThunk => async (dispatch) => {
  batch(() => {
    dispatch(setLoading(true));
    dispatch(setErrorMessage(undefined));
  });
  httpClient.get(`family`)
    .then((response) => {
      batch(() => {
        dispatch(setFamilies(response.data));
        dispatch(setLoading(false));
      });
    })
    .catch((error) => {
      batch(() => {
        dispatch(setLoading(false));
        dispatch(setErrorMessage("There was an error while trying to fetch the members list"));
        toast.error(error.response?.data.message || error.message);
      });
    });
};

export const fetchFamiliesWithDetails = (): AppThunk => async (dispatch) => {
  batch(() => {
    dispatch(setLoading(true));
    dispatch(setErrorMessage(undefined));
  });
  httpClient.get(`family/details`)
    .then((response) => {
      batch(() => {
        dispatch(setFamiliesWithDetails(response.data));
        dispatch(setLoading(false));
      });
    })
    .catch((error) => {
      batch(() => {
        dispatch(setLoading(false));
        dispatch(setErrorMessage("There was an error while trying to fetch the members list"));
      });
      toast.error(error.response?.data.message || error.message);
    });
};

export const fetchFamiliesOfMemberWithDetails = (): AppThunk => async (dispatch) => {
  batch(() => {
    dispatch(setLoading(true));
    dispatch(setErrorMessage(undefined));
  });
  httpClient.get(`family/familiesofmember`)
    .then((response) => {
      batch(() => {
        dispatch(setFamiliesWithDetails(response.data));
        dispatch(setLoading(false));
      });
    })
    .catch((error) => {
      batch(() => {
        dispatch(setLoading(false));
        dispatch(setErrorMessage("There was an error while trying to fetch the members list"));
      });
      toast.error(error.response?.data.message || error.message);
    });
};

export const familiesSelector = (state: RootState) => state.families.families;
export const familiesWithDetailsSelector = (state: RootState) => state.families.familiesWithDetail;
export const selectedFamilySelector = (state: RootState) => state.families.selectedFamily;
export const familyIsActiveSelector = (state: RootState) => state.families.selectedFamily && state.families.selectedFamily.deactivation_date == null;
export const familiesIsLoadingSelector = (state: RootState) => state.families.isLoading;
export const familiesErrorMessageSelector = (state: RootState) => state.families.errorMessage;
