const clientConfigured = (window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.REACT_APP_CLIENT_URL : process.env.REACT_APP_CLIENT_URL) ?? '';
const clientWithoutBackslash = clientConfigured.replace(/\/$/, "");
const clientWithBackslash = clientWithoutBackslash + '/';
//STS url's need to be exctly the same, so the backslash needs to match this.
export default {
    appTitle: (window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.REACT_APP_TITLE : process.env.REACT_APP_TITLE) ?? '',
    version: (window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.REACT_APP_VERSION : process.env.REACT_APP_VERSION) ?? '',
    env: (window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.REACT_APP_ENV : process.env.REACT_APP_ENV),
    apiUrl: (window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.REACT_APP_API_URL : process.env.REACT_APP_API_URL) ?? '',
    mediaUrl: (window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.REACT_APP_MEDIA_URL : process.env.REACT_APP_MEDIA_URL) ?? '',
    mediaSuffix: (window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.REACT_APP_MEDIA_SUFFIX : process.env.REACT_APP_MEDIA_SUFFIX) ?? '',
    infoUrl: (window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.REACT_APP_INFO_URL : process.env.REACT_APP_INFO_URL) ?? 'https://www.vcsobservation.com/zorg/babywatch',
    authentication: {
        authority: (window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.REACT_APP_STS_URL : process.env.REACT_APP_STS_URL) ?? '',
        clientId: "community",
        clientRoot: clientWithoutBackslash,
        scope: "openid profile email community_api",
        redirectUri: clientWithBackslash + "callback",
        automaticSilentRenew: true,
        silentRedirectUri: clientWithBackslash + "silent-renew.html",
        responseType: "code",
        postLogoutRedirectUri: clientWithoutBackslash,
        endSessionEndpoint: clientWithoutBackslash
    }
};
